import React from "react"
import TemplatePageIndustriaSalud from "@components/pageIndustrias/pageSalud/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Utiliza Beex para automatizar, con IA, la atención a pacientes</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/industria/salud/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Explora cómo Beex está transformando la atención a pacientes con IA, automatizando procesos y mejorando la gestión en el sector salud."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Utiliza Beex para automatizar, con IA, la atención a pacientes"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/industria/salud/`}
      />
       <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Explora cómo Beex está transformando la atención a pacientes con IA, automatizando procesos y mejorando la gestión en el sector salud."
      />
    </Helmet>
    <TemplatePageIndustriaSalud location={location} />
  </div>
)

export default IndexPage
